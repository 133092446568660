<script setup lang="ts">
import { isDesktop } from "~/utils/platform"

const { errorPageTestId } = useTestId()
const handleError = () => clearError({ redirect: "/home" })
</script>

<template>
  <div
    :data-testid="errorPageTestId"
    class="h-screen w-screen flex items-center justify-center t-[#{$global-t-default}]"
    :class="{
      'bg-background-window': isDesktop(),
      'bg-background-browser': !isDesktop(),
    }"
  >
    <div class="w-80">
      <div class="mb-4 w-full text-center text-xl font-medium">
        Whoops, something weird happened.
      </div>
      <div class="mb-12 text-center text-lg text-text-secondary">
        ... we've made a note of it
      </div>
      <interface-button
        class="mx-auto flex items-center justify-center rounded-lg bg-blue-light px-3 py-2 text-base font-medium hover:bg-$global-blue-primary"
        @click="handleError()"
      >
        <div class="flex items-center gap-2">
          <img
            src="/favicon.png"
            class="h-5 w-5"
          >
          <div>Go back to Home</div>
        </div>
      </interface-button>
    </div>
  </div>
</template>
