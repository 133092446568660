/**
 * In testing, we want to be able to retrieve an object by its test id, so we use
 * these global variables to easily do so.
 *
 * @returns Dictionary of test id objects
 */
export default function () {
  return {
    mainLayoutTestId: "main-layout-test-id",
    mainViewTestId: "main-view-test-id",
    errorPageTestId: "error-page-test-id",
    sharedFolderCreationButtonTestId: "shared-folder-creation-button-test-id",
    sharedFolderCreationTestId: "shared-folder-creation-test-id",

    // context menus
    contextMenuMainViewBackgroundTestId: "main-view-background-id",
    mainViewFileContextMenuTestId: "main-view-file-id",

    // sidebar
    sidebarTestId: "sidebar-test-id",
    sidebarAvatarBubbleTestId: "sidebar-avatar-bubble",
    sidebarUserDropdownTestId: "sidebar-user-dropdown",

    // navigation
    navPathViewTestId: "path-view-test-id",
    navViewModeButtonTestId: "view-mode-button-test-id",
    navViewModeDropdownTestId: "view-mode-dropdown-test-id",
    navHistoryBackTestId: "history-back-test-id",
    navHistoryForwardTestId: "history-forward-test-id",
    navSharingViewTestId: "nav-sharing-view-test-id",

    // auth
    authErrorMessageTestId: "error-message-id",
  }
}
