import { isProduction } from "~/utils/platform"
import { showConsoleEasterEgg } from "~/utils/console"

export default defineNuxtPlugin((_nuxtApp) => {
  if (isProduction()) {
    showConsoleEasterEgg()
    // remove all debug and log statements, keep warn and error
    console.debug = () => {}
    console.log = () => {}
    console.info = () => {}
    console.time = () => {}
    console.timeLog = () => {}
  }
})
