<template>
  <div class="spinner" />
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.spinner
  display: inline-block
  border-radius: 50%
  aspect-ratio: 1/1
  animation: 1s linear infinite spinning
  border: 1px solid currentcolor
  border-right-color: transparent
@keyframes spinning
  to
    transform: rotate(360deg)
</style>
