function identify(uid: string, email: string) {
  const { $posthogContext } = useNuxtApp();
  ($posthogContext as any).identify(uid, { email })
}

function capture(event: string, properties?: object) {
  const { $posthogContext } = useNuxtApp();
  ($posthogContext as any).capture(event, properties)
}

function page(url: string) {
  const { $posthogContext } = useNuxtApp();
  ($posthogContext as any).capture("$pageview", { $current_url: url })
}

function leave(url: string) {
  const { $posthogContext } = useNuxtApp();
  ($posthogContext as any).capture("$pageleave", { $current_url: url })
}

export default function () {
  return {
    identify,
    capture,
    page,
    leave,
  }
}
