<script setup lang="ts">
const { page } = useAnalytics()
addRouteMiddleware(
  "page_view",
  to => page(to.fullPath),
  { global: true },
)
</script>

<template>
  <nuxt-layout>
    <nuxt-page />
  </nuxt-layout>
</template>
