// cSpell:disable
export function showConsoleEasterEgg() {
  console.log(`%c
╭─────────────────────────────────────────────────────────────╮
│                                                             │
│                                                             │
│                                   .,lkXk                    │
│                              .;oONMMMMMMd                   │
│                         .;d0WMMWKd:..MMMMc                  │
│                    .:d0WMMN0d:.  ..  WMMMM;                 │
│               'cxKWMMNOo;.      ','  WMMMMW'                │
│          'cxXMMMNkl,.          ','   NMMlWMN.               │
│    .,lkXMMMXkl,.             .,,'    NMM ;MMK               │
│    xMMMMMM0dc;..            .,,.     NMM. lMM0              │
│     0MMKok0NMMMMWXOxl:'.    ...      XMM.  xMMx             │
│     .XMW'    .':lxOXWMMMMX0koc,..    XMM.   OMMl            │
│      .WMN. .','..     .,cok0XMMMMWXOxNMM.    KMM:           │
│       ;MMX    .',,..    ...   .';ldOWMMM0,   .NMW'          │
│        lMMO      .',,..','         xMM00MMK:  .WMN.         │
│         xMMd        .',,,'..     .XMWc  .kMMXc ,WMX.        │
│          0MM:        .,,..',,'. lWMX.     .xWMNlkMM0        │
│          .NMW'       ','      .OMMx  ....   .dWMMMMMk       │
│           .WMN.     .,,.     :NMN;  ...',,,'.  lNMMMMd      │
│            ;MMK     ',,     kMM0.         ...'.  cXMMMc     │
│             oMMO    ,,.   'XMWl                'cxXMMMK.    │
│              kMMd  .,,.  oMMK.           .,lkXMMMKxc'       │
│               KMM: .,. .KMMo         ,lkXMMMXxc'            │
│               .NMW' . :WMN,    .,lkXMMMKxc'                 │
│                .WMN. kMMk..;oONMMWKd:.                      │
│                 :MMXNMMNONMMWKx:.                           │
│                  oMMMMMW0d:.                                │
│                   x0o;.                                     │
│                                                             │
│                                           work@withpoly.com │
╰─────────────────────────────────────────────────────────────╯              
`, "font-family: monospace;")
}
