import validate from "/app/polystore/client/node_modules/nuxt/dist/pages/runtime/validate.js";
import page_45global from "/app/polystore/client/middleware/page.global.ts";
import manifest_45route_45rule from "/app/polystore/client/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  page_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/polystore/client/middleware/auth.ts")
}