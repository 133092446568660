import revive_payload_client_4sVQNw7RlN from "/app/polystore/client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/polystore/client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/polystore/client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/polystore/client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/polystore/client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/polystore/client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/polystore/client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/app/polystore/client/.nuxt/unocss.mjs";
import chunk_reload_client_UciE0i6zes from "/app/polystore/client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import analytics_client_mtqVw3YRXk from "/app/polystore/client/plugins/analytics.client.ts";
import logging_t1Zzu96LwL from "/app/polystore/client/plugins/logging.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  unocss_MzCDxu9LMj,
  chunk_reload_client_UciE0i6zes,
  analytics_client_mtqVw3YRXk,
  logging_t1Zzu96LwL
]