import * as Sentry from "@sentry/vue"
import { withScope } from "@sentry/vue"
import { posthog } from "posthog-js"

export default defineNuxtPlugin((nuxtApp) => {
  const posthogContext = posthog.init(import.meta.env.PROD
    // cspell:disable-next-line
    ? "phc_I9VjuTO9KoPpYp80uUR6ruXbPH97ILHVJEUVG1b34of"
    : "phc_wxAFyGYKXlQay1Se5EeUtD6xdImLyMO9Xle6t47VWxM", {
    api_host: "https://e.withpoly.com",
    capture_pageview: false,
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
    },
  })

  Sentry.init({
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    dsn: import.meta.env.PROD
      ? "https://eb12768837d061b6e922845a645b029b@o4507053486833664.ingest.us.sentry.io/4507053566263296"
      : "https://6d5d30f612e8cd5f1ae200839235f9f6@o4507053486833664.ingest.us.sentry.io/4507053511278592",
    environment: import.meta.env.PROD ? "production" : "development",
    integrations: [
      Sentry.browserTracingIntegration(),
      new posthog.SentryIntegration(
        posthog,
        "with-poly",
        import.meta.env.PROD ? 4507053566263296 : 4507053511278592,
      ),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/withpoly\.com\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    trackComponents: true,
    hooks: ["activate", "create", "destroy", "mount", "update"],
  })

  nuxtApp.vueApp.config.errorHandler = (err, context) => {
    withScope((scope) => {
      console.error("Nuxt app error", err)
      scope.setExtra("context", context)
      Sentry.captureException(err)
    })
  }

  nuxtApp.hook("app:error", (err) => {
    Sentry.captureException(err)
  })

  return {
    provide: {
      posthogContext,
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  }
})
