export const isServer = () => process.server

export const isClient = () => !isServer()

/** @returns true if we are in a fully production (withpoly.com) environment */
export const isProduction = () => import.meta.env.VITE_BASE_URL?.endsWith("withpoly.com")

/** @returns true if this is running fully locally */
export const isLocal = () => !import.meta.env.VITE_BASE_URL

/** @returns true if we are running in dev mode (bun run dev) */
export const isDevelopment = () => import.meta.env.DEV

/** @returns true if we are in a tauri webview */
export const isDesktop = () => isClient() && !!window.__TAURI__

/** @returns true if we are in a real browser */
export const isBrowser = () => isClient() && !isDesktop()

/** @returns true if we are on macOS safari, either on desktop or web */
export const isSafari = () => window?.safari !== undefined

/**
 * Transforms a REST endpoint into the correct URL.
 * @param request request string, e.g. `/files/blah`
 */
export const api = (request: string) => `${useRuntimeConfig().public.baseURL}api${request}`
